exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-history-tsx": () => import("./../../../src/pages/case-history.tsx" /* webpackChunkName: "component---src-pages-case-history-tsx" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../../../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../../../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-il-nostro-team-tsx": () => import("./../../../src/pages/il-nostro-team.tsx" /* webpackChunkName: "component---src-pages-il-nostro-team-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lavora-con-noi-tsx": () => import("./../../../src/pages/lavora-con-noi.tsx" /* webpackChunkName: "component---src-pages-lavora-con-noi-tsx" */),
  "component---src-pages-ricerca-tsx": () => import("./../../../src/pages/ricerca.tsx" /* webpackChunkName: "component---src-pages-ricerca-tsx" */)
}

